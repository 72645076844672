import data from "../../../data/regions.json";
import style from "../../../styles/location.module.scss";
import Link from "next/link";
import { cleanLink } from "../../../utils/helpers";

const RegionList = () => {
  return (
    <>
      <section className={`${style.locationWrap}`}>
        <div className={`${style.locationContainer} container-xl`}>
          <div className={style.content}>
            <h2>Search all UK Care Homes by Location</h2>
            <div className={style.regionList}>
              {data?.list.map((region) => (
                <div className={style.regionWrap}>
                  <Link
                    href={`/care-result/search-${region?.type}/${cleanLink(
                      region?.title
                    )}`}
                  >
                    <h3 className={style.regionHeader}>{region?.title}</h3>
                  </Link>
                  {region?.places?.length > 0 && (
                    <div className={style.cityCard}>
                      <div className={style.cityWrap}>
                        {region.places.map((city) => (
                          <div className={style.cityItem}>
                            <Link
                              href={`/care-result/search-${
                                region?.place_type
                              }/${cleanLink(city?.title)}`}
                            >
                              <h3 className={style.cityHeader}>
                                {city?.title}
                              </h3>
                            </Link>
                            <ul className={style.cityList}>
                              {city?.towns?.length > 0 &&
                                city.towns.map((local) => (
                                  <li className={style.cityListItem}>
                                    <Link
                                      href={`/care-result/search-town/${cleanLink(
                                        local
                                      )}`}
                                    >
                                      <span>{local}</span>
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegionList;
