import {
  googleCity,
  googleCounty,
  googleDistrict,
  googleAreaCode,
  googlePostCode,
} from "../data";

export const displayAddresses = (data) => {
  return [
    data?.address_1,
    data?.address_2,
    data?.address_3,
    data?.address_4,
    data?.city,
    data?.postcode,
    data?.county,
    data?.country,
  ]
    .filter((x) => !!x && x !== "''")
    .join("\n");
};

export const displayAddressType = (type) => {
  let displayType = "";
  let isFound = false;

  if (type && type?.length > 0) {
    type.map((addrType) => {
      if (!isFound) {
        if (googleCity.includes(addrType)) {
          displayType = "City";
          isFound = true;
        } else if (googleCounty.includes(addrType)) {
          displayType = "County";
          isFound = true;
        } else if (googleDistrict.includes(addrType)) {
          displayType = "District";
          isFound = true;
        } else if (googleAreaCode.includes(addrType)) {
          displayType = "Areacode";
          isFound = true;
        } else if (googlePostCode.includes(addrType)) {
          displayType = "Postcode";
          isFound = true;
        } else {
        }
      }
    });
  }
  return displayType;
};

export const validateAddressType = (types) => {
  let valid = false;
  let found = false;
  let validTypes = [
    ...googleCity,
    ...googleCounty,
    ...googleDistrict,
    ...googlePostCode,
  ];

  types?.length > 0 &&
    types?.map((addrType) => {
      if (validTypes.includes(addrType) && !found) {
        found = true;
        valid = true;
      }
    });

  return valid;
};

export const cleanLink = (dirtyLink) => {
  let link = dirtyLink ?? "";
  link.replace(" ", "%20");
  return link;
};

export const displayCardAddress = (data) => {
  let address = "";
  address = `${data?.city}`;
  if (data?.postcode) {
    address = `${address}, ${data?.postcode}`;
  }
  if (data?.company_details?.name) {
    address = `${address} - ${data?.company_details?.name}`;
  }
  return address;
};

export const displaySearchTerm = (addr, careType) => {
  let text = ``;
  if (addr) {
    text = `Search results for Care Homes in ${addr}`;
  }
  if (careType) {
    text = `Search results for Care Homes in ${addr} for ${careType} Care`;
  }
  return text;
};

export const displayCompanyAddr = (data) => {
  return [
    data?.city,
    data?.constituency,
    data?.postcode,
    data?.constituency,
    data?.region,
  ]
    .filter((x) => !!x && x !== "''")
    .join("\n");
};

export const displayDay = (date) => {
  let readableDay = "";
  if (date) {
    let dateObj = new Date(date);
    readableDay = dateObj.toDateString();
  }
  return readableDay;
};

export const displayName = (data) => {
  return [data?.first_name, data?.last_name]
    .filter((x) => !!x && x !== "''")
    .join("\n");
};

export const getJsonImages = (images) => {
  let list = [];
  if (images?.length > 0) {
    images.map((image) => {
      list.push(image?.file_path);
    });
  }
  return list;
};

let staticReview = {
  author: "",
  datePublished: "",
  name: "",
};

export const getReviews = (reviews) => {
  let list = [];

  if (reviews?.length > 0) {
    reviews?.map((row) => {
      let obj = staticReview;

      obj.author = `${row?.first_name} ${row?.last_name}`;
      obj.datePublished = `${row?.published_date ?? ""}`;
      obj.name = ``;
      obj.reviewBody = `${row?.review ?? ``}`;
      obj.reviewRating = {
        ratingValue: `${row?.rating}`,
      };

      list.push(obj);
    });
  }

  return list;
};

const getCare = (list) => {
  let string = ``;
  if (list.length > 0) {
    list.map((care, index) => {
      string =
        index === 0 ? `${care?.care_name}` : `${string} , ${care?.care_name}`;
    });
  }
  return string;
};

const getDistanceText = (location) => {
  let string = ``;
  let keys = [
    {
      key: "nearby_bus_stop",
      text: "The nearest bus stop in within",
    },
    {
      key: "nearby_local_pub",
      text: "The nearest pub in within",
    },
    {
      key: "nearby_shops",
      text: "The nearest shop in within",
    },
    {
      key: "nearby_train",
      text: "The nearest train station in within",
    },
  ];
  let values = [];
  keys.map((item) => {
    if (location[item?.key]) {
      values.push(`${item.text} ${location[item?.key]} miles`);
    }
  });
  if (values?.length > 0) {
    values.map((ele, i) => {
      string = i === 0 ? `${ele}` : `${string} , ${ele}`;
    });
  }
  return `${string}.`;
};

const validateNearby = (location) => {
  let canView = false;
  let keys = [
    "nearby_bus_stop",
    "nearby_local_pub",
    "nearby_shops",
    "nearby_train",
  ];
  let count =
    keys.filter((key) => location[key] && location[key] !== null).length ?? 0;
  if (count > 0) {
    canView = true;
  }
  return canView;
};

export const getFAQS = (currentLocation) => {
  let list = [
    {
      show: currentLocation?.type_of_care_details?.length > 0 ? true : false,
      question: `What type of elderly care is provided at ${currentLocation?.title}?`,
      answer: `${getCare(
        currentLocation?.type_of_care_details
      )} are provided at ${currentLocation?.title}.`,
    },
    {
      show: true,
      question: `Where is ${currentLocation?.title} located?`,
      answer: `It is located at ${currentLocation?.city}.`,
    },
    {
      show: currentLocation?.register_room_service_users ? true : false,
      question: `${currentLocation?.title} is registered for how many people?`,
      answer: `It is registered for ${
        currentLocation?.register_room_service_users ?? 0
      } people.`,
    },
    {
      show: validateNearby(currentLocation),
      question: `Is ${currentLocation?.title} close to local amenities?`,
      answer: `Yes it is close to local amenities. ${
        validateNearby(currentLocation) && getDistanceText(currentLocation)
      }`,
    },
  ];
  return list;
};

export const getJsonFaqs = (currentLocation) => {
  let list = getFAQS(currentLocation);
  let faqList = [];

  if (list?.length > 0) {
    list?.map((row) => {
      if (row?.show == true) {
        let obj = {};
        obj.questionName = `${row?.question}`;
        obj.acceptedAnswerText = `${row?.answer}`;
        faqList.push(obj);
      }
    });
  }
  return faqList;
};
